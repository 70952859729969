<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">当前：{{this.formData.subject_name}}-查账-明细</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="7" :sm="12" :md="8">
                                <el-date-picker
                                        v-model="pickerMonthRange"
                                        style=" margin-top: 8px;padding-top: 2px; width: 200px"
                                        type="monthrange"
                                        align="right"
                                        size="mini"
                                        @change="$_pickerMonthRangeEvent()"
                                        unlink-panels
                                        range-separator="至"
                                        start-placeholder="开始月份"
                                        end-placeholder="结束月份"
                                        :picker-options="pickerOptions">
                                </el-date-picker>
                                <el-date-picker
                                        style=" margin-top: 8px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        ref="pickerDate"
                                        :disabled="false"
                                        size="mini"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>

                            </el-col>


                            <el-col :lg="3" :sm="12" :md="4">
                                <el-button style=" margin-top: 8px; " type="primary" round @click="search()"
                                           size="mini">搜索
                                </el-button>
                                <el-button type="primary" size="mini" @click="$_openBill($refs.xGrid.getCurrentRecord())"
                                           :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           style="margin-bottom: 2px;margin-top: 2px">查看
                                </el-button>
                                <!--<el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>-->
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="3">
                                <el-form-item label="上期数:">
                                    <el-input v-model="before_money" :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="3" :sm="12" :md="3">
                                <el-form-item label="结存数:">
                                    <el-input v-model="end_money" :readonly="true" size="mini"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="4" :sm="12" :md="4">
                                <el-row>
                                    <el-col class="pull-left" :lg="4">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; margin-left: 12px" custom print export
                                                     ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>

                                </el-row>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                highlight-current-row
                                ref="xGrid"
                                align="left"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                show-footer
                                :row-class-name="$_tableRowClassName"
                                @cell-click="cellClickEvent"
                                :checkbox-config="{labelField:'id'}"
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :footer-method="footerMethod"
                                :mouse-config="{selected: true}"
                                :export-config="{mode:'current',type: ['xlsx'] ,types: ['xlsx', 'csv', 'html', 'xml', 'txt'],isFooter:true,isColgroup:true}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <!--<template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,100,500,1000,2500,5000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>-->
                        </vxe-grid>
                    </el-col>


                </el-row>

            </el-form>
        </el-main>
    </div>
</template>


<script>

    export default {

        name: "CashBanksDetail",
        data() {
            return {
                before_money:'',
                end_money:'',
                pickerDate: [],
                pickerMonthRange: [],
                formData:{
                    'id_subject':'',
                    subject_name :'',
               },
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                tableColumn: [
                    {type: 'seq', title: '序号', width: 40},
                    {type: 'checkbox',visible:false, width: 60},
                    {
                        field:'year_month', width: 100, title: '单据日期',formatter: ({cellValue}) => {return this.formatDate(cellValue,'yyyy-MM-dd')}
                   }, {
                        field:'cash_type_id', width: 50, title: '单号'
                   },{
                        field:'type_cn', width: 90, title: '单据类型'
                   },{
                        field:'cashId',visible:false,  width: 60, title: 'id'
                   },{
                        field:'summary_cash_data', width: 150, title: '摘要'
                   },{
                        field:'subject_name', width: 280, title: '科目名称'
                   },{
                        field:'company_name', width: 250, title: '往来单位'
                   },  {
                        field:'before_money', width: 100, visible:false,title: '上期数', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }
                   },{
                        field:'money', width: 140, title: '进金额', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }
                   },{
                        field:'money_sub', width: 140, title: '出金额', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }
                   },{
                        field:'balance_money',visible:true, width: 60, title: '冲账', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }
                   },{
                        field:'before_money',visible:false, width: 140, title: '小结', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }
                   },{
                        field:'b_money', width: 140, title: '结存', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }
                   },{
                        field:'check_number', width: 80, title: '支票号码'
                   },{
                        field:'draw_company', width: 100, title: '出票单位'
                   },{
                        field:'remark', width: 250, title: '备注'
                   },{
                        field:'registrar_cash_data', width: 70, title: '记录员'
                   },
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {},
           }
       },
        methods: {

            //付款单位
            subjectNameChangeEvent(item, nameType) {
                //console.log(item,nameType)
                if (nameType == 'customer_name') {
                    this.formData.subject_name = item.customer_name;
               } else if (nameType == 'supplier_name') {
                    this.formData.subject_name = item.supplier_name;
               }
                this.formData.id_subject = item.id_subject;
           },
            search() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
               this.$axios({
                    method: 'POST',
                    url: '/admin/cashBanksDetail/search',
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                        formData: this.formData,
                        isAccurate:true
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.before_money = response.data.before_money
                        this.end_money = response.data.end_money
                        this.tableDataMain = response.data.page.list
                        this.tablePage.pageSize = response.data.page.pageSize
                        this.tablePage.total = response.data.page.totalRow
                        this.tablePage.currentPage = response.data.page.pageNumber
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.search();
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                /*if (field === 'the_theory_of_count'){
                    count = this.$XEUtils.commafy(count ? count : '', {digits: 2}) + '';
               }else if(field === 'amount_of_product'){
                    count = this.$XEUtils.commafy(count ? count : '', {digits: 2}) + '';
               }else if(field === 'naked_price'){
                    count = this.$XEUtils.commafy(count ? count : '', {digits: 2}) + '';
               }*/
                return this.$XEUtils.commafy(count ? count : '', {digits: 2});
                //return count;
           },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (
                            column.property === 'money' || column.property === 'money_sub' || column.property === 'balance_money'
                        ) {
                            sums.push(this.sumNum(data, column.property))
                        }else if(column.property == 'b_money'){
                            //放最后一个结存数
                            if (data[data.length-1]!= null){
                                sums.push(this.$XEUtils.commafy(data[data.length-1][column.property], {digits: 2}))
                            }
                        } else {
                            sums.push('')
                        }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            cellClickEvent({row,rowIndex}){
                if (this.$refs.xGrid.isCheckedByCheckboxRow(row)){
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], false)
               }else{
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], true)
               }
           }

       },
        created() {
            this.$nextTick(() => {

                this.$_searchCustomerAndSupplier();
                this.loading = true
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
                if (!this.isBlankVue(this.$route.query.id_subject)){
                    this.formData.id_subject = this.$route.query.id_subject
                    this.formData.subject_name = this.$route.query.subject_name
                    //console.log('this.$route.query.startDate',this.$route.query.startDate)
                    //console.log('this.$route.query.endDate',this.$route.query.endDate)
                    //console.log('start_date',new Date(Date.parse(this.$route.query.startDate.replace(/-/g,  "/"))))
                    //console.log('end_date',new Date(Date.parse(this.$route.query.endDate.replace(/-/g,  "/"))))
                    //console.log('this.XEUtils.toStringDate(this.$route.query.startDate, \'yyyy-MM-dd\')',this.XEUtils.toStringDate(this.$route.query.startDate, 'yyyy-MM-dd'))
                    this.pickerDate.push(new Date(Date.parse(this.$route.query.startDate.replace(/-/g,  "/"))))
                    this.pickerDate.push(new Date(Date.parse(this.$route.query.endDate.replace(/-/g,  "/"))))
                    this.pickerMonthRange = this.pickerDate;
                    this.search();
               }
           })
       }
   }
</script>

<style scoped>
    /*修改行高*/
    /deep/ .vxe-table--render-default.size--mini .vxe-body--column.col--ellipsis, .vxe-table--render-default.size--mini .vxe-footer--column.col--ellipsis, .vxe-table--render-default.size--mini .vxe-header--column.col--ellipsis, .vxe-table--render-default.vxe-editable.size--mini .vxe-body--column{
        height: 24px !important;
   }
    /deep/ .vxe-table--render-default.size--mini .vxe-cell .vxe-default-input, .vxe-table--render-default.size--mini .vxe-cell .vxe-default-select, .vxe-table--render-default.size--mini .vxe-cell .vxe-default-textarea{
        height: 24px !important;
   }
    /deep/ .vxe-input--clear-icon.vxe-icon--close {
        height: 1.2em !important;
   }
    /deep/ .vxe-input.size--mini {
        height: 24px !important;
   }
    /deep/ table .el-input__inner {
        height: 22px !important;
        line-height: 24px !important;
   }
    /deep/ .vxe-table--render-default .vxe-body--column, .vxe-table--render-default .vxe-footer--column, .vxe-table--render-default .vxe-header--column {
        line-height: 20px !important;
   }
    /deep/ .vxe-button.size--mini.type--button.is--circle {
        min-width: 22px !important;
   }
    /deep/ .vxe-button.size--mini.type--button {
        height: 22px !important;
   }
    /deep/ .vxe-button.size--mini{
        font-size: 10px !important;
   }
    /*修改行高*/
</style>
